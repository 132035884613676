var $ = require('jquery');

(function($) {
    // All the JavaScript for this file.
    "use strict";

    $(window).on('load', function(){
        $('body').addClass('loaded');
        startBtnAnimate();
    });

    $("a").filter(function () {
        return this.hostname && this.hostname !== location.hostname;
    }).each(function () {
        $(this).attr({target: "_blank"});
    });

    /***********************************/
    /* BUTTON CREATIVE ANIMATE */
    /**********************************/
    function startBtnAnimate() {
        var btnCreative = $('.a-btn.creative, .a-btn-2.creative').not('.anima');

        if (btnCreative.length) {
            for (var i = 0; i < btnCreative.length; i++) {
                if ($(window).scrollTop() >= $(btnCreative[i]).offset().top - $(window).height() * 0.9) {
                    $(btnCreative[i]).addClass('anima');
                }
            }
        }
    };

    $(document).ready(function() {

    });

})(jQuery);